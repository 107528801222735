

<mat-nav-list>
    <div class="!w-full min-w-[350px] flex flex-col h-screen">
        <div class="py-2 px-3 flex flex-row justify-between border-b border-gray-300">
            <button *ngIf="menuPath.length" (click)="goBack()"><fa-icon [icon]="'arrow-left'" size="xl"></fa-icon></button>
            <div>
                <a *ngIf="!menuPath.length" (click)="goHome()" routerLink="/"><img src="assets/{{ logoUrl }}" class="h-8" alt="{{ logoAlt }}"></a>
                <span *ngIf="menuPath.length >= 1" class="font-semibold text-sm">{{ menuPath[menuPath.length - 1].item.title  }}</span>
            </div>
            <button (click)="click()" class="pr-4 mr-0.5"><fa-icon [icon]="'xmark'" size="xl" ></fa-icon></button>

        </div>

        <div class="w-full flex-grow" >
            <ng-container  *ngFor="let item of currentMenu; let i = index">   
                <div class="font-normal px-4 py-3.5 text-sm w-full hover:bg-gray-300">
                    <button *ngIf="item.submenu" (click)="openMenu(item, i)" class="flex justify-between w-full">
                        {{ item.title }} <fa-icon [icon]="'chevron-right'" class="text-sm mr-3.5"></fa-icon>
                    </button>

                    <!--  Show marques and logo -->
                    <div *ngIf="!item.submenu && menuPath[menuPath.length - 1]?.item.title === 'Marques'" class="transform transition-transform duration-1000  ">
                        <a *ngIf="!item.externeLink" (click)="click()" [routerLink]="item.link" class="text-black !w-full flex flex-row items-center" [ngClass]="item.title === 'Tout voir' ? 'font-semibold' : '' "
                        [queryParams]="item.title !== 'Tout voir' ? {from: 0} : {from: 0, marques: [item.title]}" (click)="setFiltersMarque([item.title])">
                            <!-- <img src="assets/images/logos-marques/{{ item.title }}.png" class="w-fit max-h-[3rem]" alt="{{ item.title }}"> -->
                            <!-- <span class="ml-2">{{ item.title }} </span> -->
                            {{ item.title }}
                        </a>
                    </div>

                    <!--  Show modèles -->
                    <div *ngIf="!item.submenu && menuPath[menuPath.length - 1]?.item.title === 'Modèles'" >
                        <a class="text-black !w-full" (click)="click()" [routerLink]="item.link" [ngClass]="item.marque === 'Tout voir' ? 'font-semibold' : '' "
                            [queryParams]="item.marque !== 'Tout voir' ? {from: 0} : {from: 0, marques: [item.marque],ranges: item.range, modeles: [item.modeles]}" (click)="setFiltersRanges(item)">
                       {{ item.marque }} {{ item.range }}
                        </a>
                    </div>
                    
                    <!-- show categories -->
                    <div *ngIf="!item.submenu && menuPath[menuPath.length - 1]?.item.title === 'Catégories'" >
                        <a class="text-black !w-full" (click)="click()" [routerLink]="item.link" [ngClass]="item.title === 'Tout voir' ? 'font-semibold' : '' "
                            [queryParams]="item.title !== 'Tout voir' ? {from: 0} : {from: 0, categories: item.title}" (click)="setFiltersCategories(item.title)">
                        {{ item.title }}   
                        </a>
                    </div>


                    <!-- show link menu interne -->
                    <!-- A REFACTO -->
                    <a  *ngIf="!item.submenu && !item.externeLink && menuPath[menuPath.length - 1]?.item.title !== 'Marques'
                    && menuPath[menuPath.length - 1]?.item.title !== 'Modèles'  && menuPath[menuPath.length - 1]?.item.title !== 'Catégories' && menuPath[menuPath.length - 1]?.item.title !== 'Nos rubriques'"
                        (click)="click()" [routerLink]="item.link" class="text-black !w-full flex justify-between" [ngClass]="item.title === 'Tout voir' ? 'font-semibold' : '' ">
                        {{ item.title }} <fa-icon [icon]="'chevron-right'" class="text-sm mr-3.5"></fa-icon>
                    </a>

                     <!-- show Nos rubriques-->
                    <a  *ngIf="!item.submenu && !item.externeLink && menuPath[menuPath.length - 1]?.item.title === 'Nos rubriques'"
                        (click)="click()" [routerLink]="item.link" class="text-black !w-full flex justify-between" (click)="setFilter(item.type, item.value)" [ngClass]="item.title === 'Tout voir' ? 'font-semibold' : '' ">
                        {{ item.title }} <fa-icon [icon]="'chevron-right'" class="text-sm mr-3.5"></fa-icon>
                    </a>

                    <!-- show link menu externe ( Blog ) -->
                    <a *ngIf="!item.submenu && item.externeLink" (click)="click()" href="{{ item.link }}" target="_blank" class="text-black !w-full flex justify-between" >
                        {{ item.title }} <fa-icon [icon]="'chevron-right'" class="text-sm mr-3.5"></fa-icon>
                    </a>
                    
                </div>
            </ng-container>
        </div>
        <section class="bg-[#ECECEC] mt-8 flex flex-col items-center py-4 px-3">
            <p class="text-mainBblacklue text-base font-bold">Une interrogation ?</p>
            <p class="text-[#323C48] text-base font-bold">Besoin d'un conseil ?</p>
            <a class="text-[#323C48] text-2xl font-bold mt-6 underline underline-offset-2" href="tel:0499921717">04 99 92 17 17</a>
            <span class="text-[#323C48] text-sm font-medium">Service et appel gratuits</span>
            <button class="text-white text-sm bg-greenLight rounded-lg mt-4 w-3/4 py-3" (click)="click()" routerLink="/contact">Envoyer un mail</button>
            <button class="text-white text-sm bg-[#323C48] rounded-lg mt-2 w-3/4 py-3" (click)="click()" routerLink="/agences">Trouver ma concession</button>
        </section>
    </div>
</mat-nav-list>
